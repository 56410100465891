/**
 * Forces a download of the given url with the given filename in the
 * browser.
 *
 * @param url The url to download.
 * @param filename The filename for the downloaded file.
 */
export function forceDownload(url: string, filename?: string): void {
  const anchor = document.createElement('a');
  anchor.download = filename ?? url;
  anchor.href = url;
  anchor.target = '_blank';
  anchor.click();
}
